export default {
    populateNavigation,
};

const allNavItems = {
    dashboard: { name: 'Dashboard', route: '/dashboard', icon: 'system-dashboard' },
    orders: { name: 'Leads', route: '/orders', icon: 'system-leaderboard' },
    jobs: {
        name: 'Jobs',
        icon: 'system-box-addon',
        subitems: {
            jobs: { name: 'Move jobs', route: '/jobs' },
            ancillary: { name: 'Ancillary only', route: '/ancillary' },
        },
    },
    removalist_jobs: { name: 'Jobs', route: '/jobs', icon: 'system-box-addon' },
    finance: {
        name: 'Finance',
        icon: 'system-payment',
        subitems: {
            transactions: { name: 'Transactions', route: '/finance' },
            // TODO: refactor this bc it clearly does not make sense
            removalist_payouts: { name: 'Payouts', route: '/finance' },
            payouts: { name: 'Payouts', route: '/payouts' },
            coupons: { name: 'Coupons', route: '/coupons' },
        },
    },
    companies: { name: 'Companies', route: '/companies', icon: 'system-companies' },
    interstate: { name: 'Interstate', route: '/interstate', icon: 'system-interstate' },
    depots: { name: 'Depots', route: '/depots', icon: 'system-depot' },
    local: { name: 'Local', route: '/local', icon: 'system-local' },
    fleet: { name: 'Fleet', route: '/fleet', icon: 'system-truck' },
    staff: { name: 'Staff', route: '/staff', icon: 'system-staff' },
    affiliates: {
        name: 'Affiliates',
        icon: 'system-affiliates',
        subitems: {
            partners: { name: 'Partners', route: '/affiliates' },
            leads: { name: 'Leads', route: '/affiliates/leads' },
        },
    },
    providers: { name: 'Providers', route: '/providers', icon: 'system-providers' },
    communications: {
        name: 'Communications',
        icon: 'system-email-outline',
        subitems: {
            templates: { name: 'Templates', route: '/communication' },
            automations: { name: 'Automations', route: '/automations' },
            news: { name: 'News', route: '/news' },
        },
    },
    relocations: { name: 'Relocations', route: '/relocations', icon: 'system-box' },
    policies: { name: 'Policies', route: '/policies', icon: 'system-policy' },
    corporate: {
        name: 'Corporate',
        icon: 'system-policy',
        subitems: {
            relocations: { name: 'Relocations', route: '/relocations' },
            policies: { name: 'Policies', route: '/policies' },
        },
    },
    selfy: {
        name: 'Selfy',
        icon: 'system-box',
        subitems: {
            lanes: { name: 'Lanes', route: '/selfy-lanes' },
            surcharges: { name: 'Surcharges', route: '/selfy-surcharges' },
        },
    },
    tools: {
        name: 'Tools',
        icon: 'system-tools',
        subitems: {
            historical_quotes: { name: 'Historical quotes', route: '/tools/historical-quotes' },
            other_insurance: { name: 'Covers with other', route: '/tools/other-insurance' },
            development: { name: 'Development', route: '/development' },
        },
    },
    settings: { name: 'Settings', route: '/settings', icon: 'system-settings' },
    help: { name: 'Help', route: '/help', icon: 'system-support' },
    quotes: { name: 'Quotes', route: '/quotes', icon: 'system-quote' },
};

const roleBasedNav = {
    SUPER: [
        'dashboard',
        'orders',
        'jobs:jobs',
        'jobs:ancillary',
        'finance:transactions',
        'finance:payouts',
        'finance:coupons',
        'companies',
        'interstate',
        'depots',
        'local',
        'fleet',
        'staff',
        'affiliates:partners',
        'affiliates:leads',
        'providers',
        'communications:templates',
        'communications:automations',
        'communications:news',
        'corporate:relocations',
        'corporate:policies',
        'selfy:lanes',
        'selfy:surcharges',
        'tools:historical_quotes',
        'tools:other_insurance',
        'settings',
        'help',
    ],
    MUVAL_ADMIN: [
        'dashboard',
        'orders',
        'jobs:jobs',
        'jobs:ancillary',
        'finance:transactions',
        'finance:payouts',
        'finance:coupons',
        'companies',
        'interstate',
        'depots',
        'local',
        'fleet',
        'staff',
        'affiliates:partners',
        'affiliates:leads',
        'providers',
        'communications:templates',
        'communications:automations',
        'communications:news',
        'corporate:relocations',
        'corporate:policies',
        'selfy:lanes',
        'selfy:surcharges',
        'tools:historical_quotes',
        'tools:other_insurance',
        'settings',
        'help',
    ],
    MUVAL_COMPANY: [
        'dashboard',
        'orders',
        'jobs:jobs',
        'jobs:ancillary',
        'finance:payouts',
        'companies',
        'interstate',
        'depots',
        'local',
        'fleet',
        'staff',
        'providers',
        'communications:news',
        'selfy:lanes',
        'selfy:surcharges',
        'tools:historical_quotes',
        'settings',
        'help',
    ],
    MUVAL_FINANCE: ['dashboard', 'finance:transactions', 'finance:payouts', 'settings', 'help'],
    MUVAL_AGENT: [
        'dashboard',
        'orders',
        'jobs:jobs',
        'jobs:ancillary',
        'companies',
        'interstate',
        'depots',
        'local',
        'fleet',
        'providers',
        'tools:historical_quotes',
        'settings',
        'help',
    ],
    MUVAL_TRIAGE: [
        'dashboard',
        'orders',
        'jobs:jobs',
        'jobs:ancillary',
        'companies',
        'interstate',
        'depots',
        'local',
        'fleet',
        'providers',
        'tools:historical_quotes',
        'settings',
        'help',
    ],
    COMPANY_MANAGER: [
        'dashboard',
        'quotes',
        'removalist_jobs',
        'depots',
        'local',
        'interstate',
        'finance:removalist_payouts',
        'fleet',
        'staff',
        'settings',
        'help',
    ],
    RELOCATIONS_MANAGER: ['dashboard', 'relocations', 'policies', 'settings', 'help'],
    AFFILIATE_MANAGER: ['dashboard', 'settings', 'help'],
    DEVELOPER: ['tools:development'],
};

function populateNavigation(roles) {
    const navItemsSet = new Set();

    roles.forEach((role) => {
        if (role == 'DEVELOPER') {
            return;
        }

        const roleNavItems = roleBasedNav[role] || [];
        roleNavItems.forEach((item) => navItemsSet.add(item));
    });

    if (roles.includes('DEVELOPER')) {
        roleBasedNav['DEVELOPER'].forEach((item) => navItemsSet.add(item));
    }

    const navigation = [];

    navItemsSet.forEach((item) => {
        const [key, subKey] = item.split(':');

        if (subKey) {
            let mainNavItem = navigation.find((nav) => nav.name === allNavItems[key].name);

            if (!mainNavItem) {
                mainNavItem = { ...allNavItems[key], subitems: [] };
                navigation.push(mainNavItem);
            }

            if (!mainNavItem.subitems.find((sub) => sub.name === allNavItems[key].subitems[subKey].name)) {
                mainNavItem.subitems.push(allNavItems[key].subitems[subKey]);
            }
        } else {
            if (!navigation.find((nav) => nav.name === allNavItems[key].name)) {
                navigation.push(allNavItems[key]);
            }
        }
    });

    return navigation;
}
