export default [
    {
        path: '/selfy-lanes',
        name: 'SelfyLaneIndex',
        meta: {
            requiresAuth: true,
            title: 'Selfy Lanes',
        },
        components: {
            default: () => import('@views/selfyLanes/SelfyLaneIndex.vue'),
            agent: () => import('@views/selfyLanes/SelfyLaneIndex.vue'),
        },
    },
    {
        path: '/selfy-lanes/create',
        name: 'SelfyLaneCreate',
        props: {
            default: () => ({ isCreate: true }),
            agent: () => ({ isCreate: true }),
        },
        meta: {
            requiresAuth: true,
            title: 'Create Selfy Lane',
        },
        components: {
            default: () => import('@views/selfyLanes/SelfyLaneForm.vue'),
            agent: () => import('@views/selfyLanes/SelfyLaneForm.vue'),
        },
    },
    {
        path: '/selfy-lanes/:id/edit',
        name: 'SelfyLaneEdit',
        props: {
            default: (route) => ({ id: route.params.id, isCreate: false }),
            agent: (route) => ({ id: route.params.id, isCreate: false }),
        },
        meta: {
            requiresAuth: true,
            title: 'Edit Selfy Lane',
        },
        components: {
            default: () => import('@views/selfyLanes/SelfyLaneForm.vue'),
            agent: () => import('@views/selfyLanes/SelfyLaneForm.vue'),
        },
    },
];
