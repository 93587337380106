import { defineStore } from 'pinia';

export const useInsuranceStore = defineStore('insurance', () => {
    // state
    const loading = ref(false);
    const data = ref({});
    const categories = ref([]);

    // stores
    const orderStore = useOrderStore();
    const moveStore = useMoveStore();

    /*
     * Retrieve an insurance
     */
    async function retrieve(orderId = orderStore.getOrderId) {
        try {
            loading.value = true;
            const resp = await window.axios.get(`/orders/${orderId}/insurance`);
            console.log(resp);
            data.value = resp.data.insurance;
            loading.value = false;
        } catch (error) {
            console.log('Error retrieving insurance', error);
            throw error;
        }
    }

    /*
     * Create an insurance
     */
    async function create(orderId = orderStore.getOrderId, insuranceData) {
        try {
            loading.value = true;
            const { data: data } = await window.axios.post(`/orders/${orderId}/insurance`, {
                ...insuranceData,
            });
            data.value = data.insurance;
            loading.value = false;
        } catch (error) {
            console.log('Error creating insurance', error);
            throw error;
        }
    }

    /*
     * Update an insurance
     */
    async function update(orderId = orderStore.getOrderId, insuranceData) {
        try {
            loading.value = true;
            const resp = await window.axios.patch(`/orders/${orderId}/insurance`, {
                ...insuranceData,
            });
            console.log(resp);
            data.value = resp.data.insurance;
            loading.value = false;
        } catch (error) {
            console.log('Error updating insurance', error);
            throw error;
        }
    }

    /*
     * Cancel an insurance
     */
    async function cancel(orderId = orderStore.getOrderId, data = {}) {
        try {
            loading.value = true;
            const response = await window.axios.post(`/orders/${orderId}/insurance/cancel`, data);
            console.log('Cancelling insurance', response);
            loading.value = false;
        } catch (error) {
            console.log('Error deleting insurance', error);
            throw error;
        }
    }

    async function enable(orderId = orderStore.getOrderId) {
        try {
            loading.value = true;
            const response = await window.axios.post(`/orders/${orderId}/insurance/enable`);
            console.log('Enabling insurance', response);
            loading.value = false;
        } catch (error) {
            console.log('Error enabling insurance', error);
            throw error;
        }
    }

    async function deleteHistory(insuranceId, premiumId) {
        try {
            loading.value = true;
            const response = await window.axios.delete(`/insurances/${insuranceId}/premium/${premiumId}`);
            console.log('Deleting insurance history', response);
            loading.value = false;
        } catch (error) {
            console.log('Error deleting insurance history', error);
            throw error;
        }
    }

    async function getCategories() {
        try {
            loading.value = true;
            if (!categories.value.length) {
                const {
                    data: { data: res },
                } = await window.axios.get('inventory-categories?type=INSURANCE');
                categories.value = res;
            }
            loading.value = false;
        } catch (error) {
            console.log('Error getting insurance categories', error);
            throw error;
        }
    }

    async function calculatePremium(orderId = orderStore.getOrderId, data) {
        try {
            const response = await window.axios.post(`/orders/${orderId}/insurance/calculate`, data);
            return response.data.data;
        } catch (error) {
            console.log('Error calculating premium', error);
            throw error;
        }
    }

    const getInsurance = computed(() => {
        return data.value;
    });

    const getLatestPremium = computed(() => {
        return data.value?.latest_premium;
    });

    const getStatus = computed(() => {
        return data.value?.status;
    });

    const hasPaid = computed(() => {
        return data.value?.payment_status === 'PAID';
    });

    const isConfirmed = computed(() => {
        return data.value?.order_item_status === 'CONFIRMED';
    });

    const getCategoriesFormatted = computed(() => {
        if (!categories.value.length) return [];

        return categories.value
            .filter(
                (category) =>
                    category.type === 'INSURANCE' && (category.insurance_options.length || category.slug === 'custom'),
            )
            .map((category) => {
                const { id, name, slug, insurance_options } = category;
                return {
                    id,
                    name,
                    slug,
                    insurance_options,
                    icon: getInsuranceIcon(category),
                };
            });
    });

    const isMX = computed(() => {
        return moveStore.isMuvexpress;
    });

    return {
        // STATE
        loading,
        data,
        categories,

        // ACTIONS
        retrieve,
        create,
        update,
        cancel,
        enable,
        deleteHistory,
        getCategories,
        calculatePremium,

        // GETTERS
        getInsurance,
        getLatestPremium,
        getStatus,
        hasPaid,
        isConfirmed,
        getCategoriesFormatted,
        isMX,
    };
});

function getInsuranceIcon(category) {
    switch (category.slug) {
        case 'dinner_lounge':
            return 'dining';
        case 'garage_shed':
            return 'garage';
        case 'other':
            return 'other';
        case 'custom':
            return 'miscellaneous';
        default:
            return category.name.toLowerCase();
    }
}
