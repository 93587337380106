export default [
    {
        path: '/selfy-surcharges',
        name: 'SelfySurchargeIndex',
        meta: {
            requiresAuth: true,
            title: 'Selfy Surcharges',
        },
        components: {
            default: () => import('@views/selfySurcharges/SelfySurchargeIndex.vue'),
            agent: () => import('@views/selfySurcharges/SelfySurchargeIndex.vue'),
        },
    },
    {
        path: '/selfy-surcharges/create',
        name: 'SelfySurchargeCreate',
        props: {
            default: () => ({ isCreate: true }),
            agent: () => ({ isCreate: true }),
        },
        meta: {
            requiresAuth: true,
            title: 'Create Selfy Surcharge',
        },
        components: {
            default: () => import('@views/selfySurcharges/SelfySurchargeForm.vue'),
            agent: () => import('@views/selfySurcharges/SelfySurchargeForm.vue'),
        },
    },
    {
        path: '/selfy-surcharges/:id/edit',
        name: 'SelfySurchargeEdit',
        props: {
            default: (route) => ({ id: route.params.id, isCreate: false }),
            agent: (route) => ({ id: route.params.id, isCreate: false }),
        },
        meta: {
            requiresAuth: true,
            title: 'Edit Selfy Surcharge',
        },
        components: {
            default: () => import('@views/selfySurcharges/SelfySurchargeForm.vue'),
            agent: () => import('@views/selfySurcharges/SelfySurchargeForm.vue'),
        },
    },
];
