export function useActivityStatuses() {
    const statuses = [
        {
            name: 'Available',
            value: 'AVAILABLE',
            colour: 'bg-muval-success',
        },
        {
            name: 'Idle',
            value: 'IDLE',
            colour: 'bg-muval-gray-4',
        },
        {
            name: 'Out for lunch',
            value: 'OUT_FOR_LUNCH',
            colour: 'bg-muval-error',
        },
        {
            name: 'On a break',
            value: 'ON_A_BREAK',
            colour: 'bg-muval-error',
        },
        {
            name: 'In training',
            value: 'IN_TRAINING',
            colour: 'bg-muval-error',
        },
        {
            name: 'Back office',
            value: 'BACK_OFFICE',
            colour: 'bg-muval-error',
        },
        {
            name: 'In a meeting',
            value: 'IN_A_MEETING',
            colour: 'bg-muval-error',
        },
        {
            name: 'Unavailable',
            value: 'UNAVAILABLE',
            colour: 'bg-muval-error',
        },
        {
            name: 'After hours',
            value: 'AFTER_HOURS',
            colour: 'bg-muval-error',
        },
    ];

    const statusesWithDisabled = statuses.map((status) => {
        return {
            ...status,
            disabled: status.value === 'IDLE',
        };
    });

    function getStatusColourByValue(status) {
        const foundStatus = statuses.find((s) => s.value === status);
        return foundStatus ? foundStatus.colour : '';
    }

    function getStatusNameByValue(status) {
        const foundStatus = statuses.find((s) => s.value === status);
        return foundStatus ? foundStatus.name : '';
    }

    return {
        statuses,
        statusesWithDisabled,
        getStatusColourByValue,
        getStatusNameByValue,
    };
}
