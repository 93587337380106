export default [
    {
        path: '/tools/historical-quotes',
        name: 'historical_quotes',
        meta: {
            requiresAuth: true,
            title: 'Historical quotes',
        },
        components: {
            agent: () => import('@views/tools/HistoricalQuoteIndex.vue'),
        },
    },
    {
        path: '/tools/other-insurance',
        name: 'other_insurance',
        meta: {
            requiresAuth: true,
            title: 'Covers with other',
        },
        components: {
            agent: () => import('@views/tools/OtherInsuranceIndex.vue'),
        },
    },
];
