/**
 * Vue Router
 *
 * @package Muval Frontend Project
 * @copyright 2021 Muval Pty Ltd. All rights reserved.
 * @author Tim Maier
 */

// Domains
import orderRoutes from './domains/orderRoutes';
import depotRoutes from './domains/depotRoutes';
import couponRoutes from './domains/couponRoutes';
import staffRoutes from './domains/staffRoutes';
import userRoutes from './domains/userRoutes';
import leadRoutes from './domains/leadRoutes';
import companyRoutes from './domains/companyRoutes';
import localPriceRoutes from './domains/localPriceRoutes';
import interstateRoutes from './domains/interstateRoutes';
import fleetRoutes from './domains/fleetRoutes';
import companiesRoutes from './domains/companiesRoutes';
import communicationRoutes from './domains/communicationRoutes';
import jobRoutes from './domains/jobRoutes';
import affiliateRoutes from './domains/affiliateRoutes';
import providerRoutes from './domains/providerRoutes';
import settingsRoutes from './domains/settingsRoutes';
import ancillaryRoutes from './domains/ancillaryRoutes';
import quoteRoutes from './domains/quoteRoutes';
import customerAutomationsRoutes from './domains/customerAutomationsRoutes';
import newsRoutes from './domains/newsRoutes';
import relocationsRoutes from './domains/relocationsRoutes';
import selfyLaneRoutes from './domains/selfyLaneRoutes';
import selfySurchargeRoutes from './domains/selfySurchargeRoutes';
import toolsRoutes from './domains/toolsRoutes';
import { useUserStore } from '@store/user';
import { useAppStore } from '../store/app';

const router = createRouter({
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                el: to.hash,
            };
        }
        if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0 };
        }
    },
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            redirect: { name: 'Dashboard' },
        },
        {
            path: '/login',
            name: 'Login',
            meta: {
                requiresAuth: false,
                hideForAuth: true,
                fullscreen: true,
            },
            components: {
                default: () => import('../views/NewSignInView.vue'),
                agent: () => import('../views/NewSignInView.vue'),
            },
        },
        {
            path: '/reset-password',
            name: 'ForgotPassword',
            props: {
                default: (route) => ({ token: route.query.token }),
            },
            meta: {
                requiresAuth: false,
                hideForAuth: true,
                fullscreen: true,
            },
            components: {
                default: () => import('../views/NewForgotPassword.vue'),
                agent: () => import('../views/NewForgotPassword.vue'),
            },
        },
        {
            path: '/register',
            name: 'Register',
            meta: {
                requiresAuth: false,
                hideForAuth: true,
                fullscreen: true,
            },
            components: {
                default: () => import('../views/NewRegistrationView.vue'),
                agent: () => import('../views/NewRegistrationView.vue'),
            },
        },
        {
            path: '/preferences',
            name: 'Update your preferences',
            meta: {
                requiresAuth: false,
                hideForAuth: false,
            },
            components: {
                default: () => import('../views/PreferencesView.vue'),
                agent: () => import('../views/PreferencesView.vue'),
            },
        },
        {
            path: '/dashboard',
            name: 'Dashboard',
            meta: {
                requiresAuth: true,
            },
            components: {
                default: () => import('../views/dashboard/DashboardRemovalist.vue'),
                agent: () => import('../views/dashboard/DashboardIndex.vue'),
                relocations: () => import('../views/relocations/Dashboard.vue'),
                affiliates: () => import('../views/affiliates/AffiliatesLeads.vue'),
            },
        },
        {
            path: '/me',
            name: 'My Account',
            meta: {
                requiresAuth: true,
            },
            components: {
                default: () => import('../views/MyAccountView.vue'),
                agent: () => import('../views/MyAccountView.vue'),
                relocations: () => import('../views/MyAccountView.vue'),
            },
        },
        {
            path: '/search',
            name: 'Search',
            meta: {
                requiresAuth: true,
            },
            components: {
                default: () => import('../views/search/GlobalSearch.vue'),
                agent: () => import('../views/search/GlobalSearch.vue'),
            },
        },
        {
            path: '/development',
            name: 'Development',
            meta: {
                requiresAuth: true,
            },
            components: {
                default: () => import('../views/DevPage.vue'),
                agent: () => import('../views/DevPage.vue'),
            },
        },
        {
            path: '/help',
            name: 'Help',
            meta: {
                requiresAuth: true,
                title: 'Need help?',
            },
            components: {
                default: () => import('@views/HelpPage.vue'),
                agent: () => import('@views/HelpPage.vue'),
                relocations: () => import('@views/HelpPage.vue'),
                affiliates: () => import('@views/HelpPage.vue'),
            },
        },

        // Domain Routes
        ...leadRoutes,
        ...orderRoutes,
        ...depotRoutes,
        ...couponRoutes,
        ...companyRoutes,
        ...companiesRoutes,
        ...staffRoutes,
        ...userRoutes,
        ...communicationRoutes,
        ...localPriceRoutes,
        ...interstateRoutes,
        ...fleetRoutes,
        ...jobRoutes,
        ...affiliateRoutes,
        ...providerRoutes,
        ...settingsRoutes,
        ...ancillaryRoutes,
        ...quoteRoutes,
        ...customerAutomationsRoutes,
        ...newsRoutes,
        ...relocationsRoutes,
        ...selfyLaneRoutes,
        ...selfySurchargeRoutes,
        ...toolsRoutes,
        {
            path: '/inbox/calls',
            name: 'InboxCalls',
            meta: {
                requiresAuth: true,
            },
            components: {
                default: () => import('../views/inbox/CallsIndex.vue'),
                agent: () => import('../views/inbox/CallsIndex.vue'),
            },
        },
        {
            path: '/inbox/sms',
            name: 'InboxSms',
            meta: {
                requiresAuth: true,
            },
            components: {
                default: () => import('../views/inbox/SmsIndex.vue'),
                agent: () => import('../views/inbox/SmsIndex.vue'),
            },
        },
        {
            path: '/finance',
            name: 'Finance',
            meta: {
                requiresAuth: true,
                title: 'Finance',
            },
            components: {
                default: () => import('../views/finance/FinanceIndexRemovalist.vue'),
                agent: () => import('../views/finance/FinanceIndex.vue'),
            },
        },
        {
            path: '/payouts',
            name: 'Payouts',
            meta: {
                requiresAuth: true,
                title: 'Payouts',
            },
            components: {
                default: () => import('../views/payouts/PayoutsIndex.vue'),
                agent: () => import('../views/payouts/PayoutsIndex.vue'),
            },
        },
        {
            path: '/scheduler',
            name: 'Scheduler',
            meta: {
                requiresAuth: true,
            },
            components: {
                default: () => import('../views/scheduler/SchedulerIndex.vue'),
                agent: () => import('../views/scheduler/SchedulerIndex.vue'),
            },
        },
        {
            path: '/communications',
            name: 'Communications',
            meta: {
                requiresAuth: true,
            },
            components: {
                default: () => import('../views/communication/CommunicationsIndex.vue'),
                agents: () => import('../views/communication/CommunicationsIndex.vue'),
            },
        },
        {
            path: '/not_authorised',
            name: '403Page',
            components: {
                default: () => import('../views/403Page.vue'),
                agent: () => import('../views/403Page.vue'),
            },
        },
        {
            path: '/service_unavailable',
            name: '503Page',
            components: {
                default: () => import('../views/503Page.vue'),
                agent: () => import('../views/503Page.vue'),
            },
        },
        {
            path: '/not_found',
            name: '404Page',
            components: {
                default: () => import('../views/404Page.vue'),
                agent: () => import('../views/404Page.vue'),
            },
        },
        {
            path: '/:pathMatch(.*)*',
            meta: {
                requiresAuth: true,
            },
            components: {
                default: () => import('../views/404Page.vue'),
                agent: () => import('../views/404Page.vue'),
            },
        },
    ],
});
/*eslint-disable */

router.beforeEach((to, from, next) => {
    const app = useAppStore();

    // Find abort controller and abort all requests tied to it and then remove it from the store
    const foundAbortController = app.axiosAbortControllerList[from.path];
    if (foundAbortController && from.path != '/' && from.path != to.path) {
        console.log('foundAbortController', foundAbortController);
        foundAbortController.controller.abort();
        delete app.axiosAbortControllerList[from.path];
    }

    const currentUser = useUserStore();
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    const hideForAuth = to.matched.some((record) => record.meta.hideForAuth);
    const requiresRoles = to.matched.some(
        (record) =>
            !record.meta?.roles?.some((role) => {
                return currentUser?.getRoles?.includes(role);
            }),
    );
    if (requiresAuth && !currentUser.isAuthenticated) {
        const loginPath = window.location.pathname;
        next({ name: 'Login', query: { from: loginPath } });
    } else if (hideForAuth && currentUser.isAuthenticated) next({ name: 'Dashboard' });
    else next();

    app.side = false;
    // // Todo check meta.roles to see if current store.getters['User/getRoles'] includes access otherwise redirect to 403
    // if (
    //   to.meta.roles &&
    //   !to.meta.roles.some((r) => {
    //     return user.getRoles?.includes(r);
    //   })
    // ) {
    //   next({ name: "403Page" });
    // }
    // if (to.name === "Login" && user.isAuthenticated) {
    //   next({ name: "Dashboard" });
    // } else if (to.name === "Register" && user.isAuthenticated) {
    //   next({ name: "Dashboard" });
    // } else if (to.meta && to.meta.requiresAuth === false) {
    //   next(); // requires auth is explicitly set to false
    // } else if (user.isAuthenticated) {
    //   next(); // i'm logged in. carry on
    // } else {
    //   next({ name: "Login" }); // always put your redirect as the default case
    // }
});
export default router;
